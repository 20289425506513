import { useState, useEffect, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Check, Sort, Eye, Pen, Delete, Weight } from '../../assets/icons'
import { CustomTooltip, Alert, Checkbox } from '../ui_new'
import { EditAttachmentModal, AttachmentsActions } from './'
import { arrayWithOrderedIds, defaultAttachmentsForDocument, FEATURE, isFeatureEnabled, sortArrayOfObjects } from '../../utils'
import { useAttachmentActions } from '../../hooks'
import { NotificationContext, LoaderContext } from '../../context'
import byteConverter from '../../helpers/byteConverter'
import { AttachmentsContext } from '../../context/attachments/attachmentsState'
import { compress_attachment } from '../../services/lawstudioApi'


const SingleDocumentAttachments = ({ attachments, docId, singleDoc, template, onSetDocumentAttachments, onSetView, onSetTab, isSidePanelOpen, onToggleSidePanel }) => {
  
  const { defaultAttachments, defaultAttachmentsOrder } = useContext(AttachmentsContext)

  const [allChecked, setAllChecked] = useState(false)
  const [selectedAttachments, setSelectedAttachments] = useState([])
  const [filteredAttachments, setFilteredAttachments] = useState([])
  const [activeSort, setActiveSort] = useState('name')
  const [activeOrder, setActiveOrder] = useState('asc')
  const [filteredDefaultAttachments, setFilteredDefaultAttachments] = useState([])

  // Set filtered attachments on attachments change
  useEffect(() => {
    if (attachments) {
      let atts = [...attachments]
      for (let i = 0; i < atts.length; i++) {
        let att = atts[i]
        if (!att.id) {
          att.id = uuidv4()
        }
      }
      setFilteredAttachments(sortArrayOfObjects(atts, 'name', 'asc'))
    }
  }, [attachments])

  // Set filtered default attachments on defaultAttachments change
  useEffect(() => {
    if(defaultAttachments) {
      let atts = Object.keys(defaultAttachments).map(key => ({
        ...defaultAttachments[key],
        id: key
      }))
      atts = defaultAttachmentsForDocument(defaultAttachments, template, singleDoc.values)
      setFilteredDefaultAttachments(arrayWithOrderedIds(atts, defaultAttachmentsOrder))
    }
  }, [defaultAttachments, defaultAttachmentsOrder, template, singleDoc])


  // On all checkbox click
  const handleAllChecked = () => {
    setAllChecked(!allChecked)
    if (allChecked) {
      setSelectedAttachments([])
    } else {
      setSelectedAttachments(filteredAttachments)
    }
  }

  // On sort
  const handleSort = (sort_by) => {
    const atts = sortArrayOfObjects(filteredAttachments, sort_by, activeOrder === 'desc' ? 'asc' : 'desc')
    setActiveSort(sort_by)
    setActiveOrder(activeOrder === 'desc' ? 'asc' : 'desc')
    setFilteredAttachments(atts)
  }

  // On upload btn click - switch to upload view
  const handleUploadClick = (e) => {
    onSetView('attachments')
    if (!isSidePanelOpen) onToggleSidePanel(true)
  }
  const attachmentTotalMaxSize = 2.56e+8

  const printSize = (bytes) => {
    if (bytes > 1000000) {
      return `${Math.round(bytes / 1000000).toFixed(0)}MB`
    } else if (bytes > 1000) {
      return `${Math.round(bytes / 1000).toFixed(0)}KB`
    }
  }

  // const printType = (mimetype) => {
  //     return mimetype.split('/')[1].toUpperCase()
  // }

  const calculateSize = () => {
    let size = 0
    for (let att of filteredAttachments) {
      size += att.size
    }
    return size
  }

  return (
    <>
      {selectedAttachments.length > 0 && (
        <div className="single-document-attachments__actions">
          <AttachmentsActions
            selectedAttachments={selectedAttachments}
            onSetSelectedAttachments={setSelectedAttachments}
            attachments={attachments}
            onSetDocumentAttachments={onSetDocumentAttachments}
            onSetAllChecked={setAllChecked}
            docId={docId}
          />
        </div>
      )}
      <div className="single-document-attachments">
        <div className="single-document-attachments__head">
          {/* <h2>Annexes</h2><br /> */}
          <h2>Vos annexes</h2>
        </div>
        <div className="documents-table-parent">
          <div className="documents-table">
            <div className="documents-table__head no-border-radius">
              <div className="documents-table-row">
                {/* <div className="documents-table-column">
                <div className={`attachments-table-column__check ${allChecked ? 'checked' : ''}`} onClick={handleAllChecked}><Check /></div>
              </div> */}
                <div className="documents-table-column documents-table-column__fileName" onClick={() => handleSort('name')}>
                  <p className={`${activeSort === 'name' ? 'active' : ''}`}>Nom</p>
                </div>
                <div className="documents-table-column documents-table-column__size" onClick={() => handleSort('name')}>
                  <p className={`${activeSort === 'name' ? 'active' : ''}`}>Poids</p>
                </div>
                <div className="documents-table-column documents-table-column__actions" >
                  <p>Actions</p>
                </div>
              </div>
            </div>
            <div className="documents-table__body">
              {filteredAttachments.map((attachment, idx) => {
                return (
                  <AttachmentRow
                    key={idx}
                    attachment={attachment}
                    attachments={attachments}
                    onSetSelectedAttachments={setSelectedAttachments}
                    selectedAttachments={selectedAttachments}
                    allChecked={allChecked}
                    docId={docId}
                    onSetDocumentAttachments={onSetDocumentAttachments}
                  />
                )
              })}
            </div>
            <div className='px-3'>
              <p className="queta">
                <span>Format uniquement supporté : .PDF</span>
                <div className='flex flex-col justify-end'>
                  <span>Taille maximale : {`${printSize(attachmentTotalMaxSize)}`}</span>
                  <b>Poids actuel : {`${calculateSize() ? printSize(calculateSize()) : '0'}`}</b>
                </div>
              </p>
              {calculateSize() > attachmentTotalMaxSize && <div className='mb-20'>
                <p className='text-error' >Le poids total des documents joints dépasse {printSize(attachmentTotalMaxSize)}</p>
              </div>}
            </div>
          </div>
        </div>

        <div className="single-document-attachments__foot">
          <button className="btn" onClick={handleUploadClick}>Télécharger de nouvelles pièces jointes</button>
        </div>
        {/* attachments */}<br /><br /><br />

        {isFeatureEnabled(FEATURE.DEFAULT_ATTACHMENTS) && (
          <>
            <div className="single-document-attachments__head">
              {/* <h2>Annexes</h2><br /> */}
              <h2>Annexes Immo Docs</h2>
            </div>
            <div className="single-document-attachments__body">
              <div className="documents-table">
                <div className="documents-table__head no-border-radius">
                  <div className="documents-table-row">
                    <div className="documents-table-column" onClick={() => handleSort('name')}>
                      <p className={`${activeSort === 'name' ? 'active' : ''}`}>Nom</p>
                    </div>
                    <div className="documents-table-column" onClick={() => handleSort('name')}>
                      <p className={`${activeSort === 'name' ? 'active' : ''}`}>Poids</p>
                    </div>
                    <div className="documents-table-column">
                      <p>Actions</p>
                    </div>
                  </div>
                </div>
                <div className="documents-table__body">
                  {filteredDefaultAttachments.map((attachment, idx) => {
                    return (
                      <DefaultAttachmentRow
                        key={idx}
                        attachment={attachment}
                        attachments={attachments}
                        onSetSelectedAttachments={setSelectedAttachments}
                        selectedAttachments={selectedAttachments}
                        allChecked={allChecked}
                        docId={docId}
                        onSetDocumentAttachments={onSetDocumentAttachments}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

const AttachmentRow = ({ attachment, attachments, onSetSelectedAttachments, selectedAttachments, allChecked, docId, onSetDocumentAttachments }) => {
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { previewAttachment, editAttachment, deleteAttachment } = useAttachmentActions(attachment, attachments, docId)
  const [checked, setChecked] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)

  // Set checked to false if selectedAttachments length is zero
  useEffect(() => {
    if (checked && selectedAttachments.length === 0) {
      setChecked(false)
    }
  }, [selectedAttachments, checked])

  // Set checked to true if allChecked is true
  useEffect(() => {
    if (allChecked) {
      setChecked(true)
    }
  }, [allChecked])

  // On attachment select
  const handleSelectAttachment = () => {
    setChecked(!checked)
    if (checked) {
      onSetSelectedAttachments(prev => prev.filter(a => a.id !== attachment.id))
    } else {
      onSetSelectedAttachments(prev => [...prev, attachment])
    }
  }

  // On preview
  const handlePreview = () => {
    previewAttachment()
  }

  // On edit modal open
  const handleOpenEditModal = () => {
    setShowEditModal(true)
  }

  // On edit modal close
  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }

  // On edit 
  const handleEdit = async (name) => {
    const attachmentsNames = attachments.filter(a => a.id === attachment.id).map(a => a.name)
    if (attachmentsNames.includes(name)) {
      return setNotification({ msg: 'La pièce jointe portant ce nom existe déjà', type: 'danger' })
    }

    setShowGlobalResponseLoader(true)
    try {
      const updatedAttachments = await editAttachment(name)
      onSetDocumentAttachments(updatedAttachments)
      setShowEditModal(false)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  // On delete alert open
  const handleOpenDeleteAlert = () => {
    setShowDeleteAlert(true)
  }

  // On delete alert close
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On delete
  const handleDelete = async () => {
    setShowGlobalResponseLoader(true)
    setShowDeleteAlert(false)
    try {
      const updatedAttachments = await deleteAttachment()
      onSetDocumentAttachments(updatedAttachments)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  return (
    <div className={`documents-table-row ${checked ? 'selected' : ''}`}>
      <div className="documents-table-column documents-table-column__fileName">
        <div className={`attachments-table-column__check ${checked ? 'checked' : ''}`}>
          <Checkbox checked={checked} onChange={handleSelectAttachment} />
        </div>
        <p>{attachment.name}</p>
      </div>
      <div className="documents-table-column documents-table-column__size">
        <p>{byteConverter(attachment.size)}</p>
      </div>
      <div className="documents-table-column documents-table-column__actions">
        <div className="flex mx-2 gap-2">
          <CustomTooltip content="Aperçu">
            <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={handlePreview}>
              <Eye />
            </button>
          </CustomTooltip>
          <CustomTooltip content="Renommer">
            <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={handleOpenEditModal}>
              <Pen />
            </button>
          </CustomTooltip>
          <CustomTooltip content="Supprimer">
            <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={handleOpenDeleteAlert}>
              <Delete />
            </button>
          </CustomTooltip>
        </div>
      </div>


      {showEditModal && (
        <EditAttachmentModal
          onClose={handleCloseEditModal}
          attachment={attachment}
          onEdit={handleEdit}
        />
      )}

      {showDeleteAlert && (
        <Alert
          onClose={handleCloseDeleteAlert}
          text={`Êtes-vous sûr de vouloir supprimer cette pièce jointe - ${attachment.name} ?`}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}
    </div>
  )
}

const DefaultAttachmentRow = ({ attachment, attachments, onSetSelectedAttachments, selectedAttachments, docId, onSetDocumentAttachments }) => {
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { previewAttachment, editAttachment, deleteAttachment, weightAttachment } = useAttachmentActions(attachment, attachments, docId)
  const [checked, setChecked] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [showWeightAlert, setShowWeightAlert] = useState(false)
  const [showCompressedSizeAlert, setShowCompressedSizeAlert] = useState(false)


  // On attachment select
  const handleSelectAttachment = () => {
    setChecked(!checked)
    if (checked) {
      onSetSelectedAttachments(prev => prev.filter(a => a.id !== attachment.id))
    } else {
      onSetSelectedAttachments(prev => [...prev, attachment])
    }
  }

  // On preview
  const handlePreview = () => {
    previewAttachment()
  }

  // On edit modal open
  const handleOpenEditModal = () => {
    setShowEditModal(true)
  }

  // On edit modal close
  const handleCloseEditModal = () => {
    setShowEditModal(false)
  }

  // On edit 
  const handleEdit = async (name) => {
    const attachmentsNames = attachments.filter(a => a.id === attachment.id).map(a => a.name)
    if (attachmentsNames.includes(name)) {
      return setNotification({ msg: 'La pièce jointe portant ce nom existe déjà', type: 'danger' })
    }

    setShowGlobalResponseLoader(true)
    try {
      const updatedAttachments = await editAttachment(name)
      onSetDocumentAttachments(updatedAttachments)
      setShowEditModal(false)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  // On delete alert open
  const handleOpenDeleteAlert = () => {
    setShowDeleteAlert(true)
  }

  // On delete alert close
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On delete
  const handleDelete = async () => {
    setShowGlobalResponseLoader(true)
    setShowDeleteAlert(false)
    try {
      const updatedAttachments = await deleteAttachment()
      onSetDocumentAttachments(updatedAttachments)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  // On weight alert open
  const handleOpenWeightAlert = () => {
    setShowWeightAlert(true)
  }

  // On weight alert close
  const handleCloseWeightAlert = () => {
    setShowWeightAlert(false)
  }

  // On weight
  const handleWeight = async () => {
    setShowGlobalResponseLoader(true)
    setShowWeightAlert(false)
    try {
      const updatedAttachments = await weightAttachment();
      // set compressed size
      setShowCompressedSizeAlert(250000)
      // onSetDocumentAttachments(updatedAttachments)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  // On weight alert close
  const handleCloseCompressedSizeAlert = () => {
    setShowCompressedSizeAlert()
  }

  return (
    <div className={`documents-table-row`}>
      <div className="documents-table-column documents-table-column__fileName">
        <p>{attachment.name}</p>
      </div>
      <div className="documents-table-column documents-table-column__size">
        <p>({byteConverter(attachment.size)})</p>
      </div>
      <div className="documents-table-column documents-table-column__actions">
        <div className="flex mx-2 gap-2">
          <CustomTooltip content="Aperçu">
            <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={handlePreview}><Eye /></button>
          </CustomTooltip>
          {/* <CustomTooltip content="Renommer">
            <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={handleOpenEditModal}><Pen /></button>
          </CustomTooltip> */}
          {/* <CustomTooltip content="Supprimer">
            <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={handleOpenDeleteAlert}><Delete /></button>
          </CustomTooltip> */}
          {/* <CustomTooltip content="Compresse">
            <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={handleOpenWeightAlert}><Weight /></button>
          </CustomTooltip> */}
        </div>
      </div>


      {showEditModal && (
        <EditAttachmentModal
          onClose={handleCloseEditModal}
          attachment={attachment}
          onEdit={handleEdit}
        />
      )}

      {showDeleteAlert && (
        <Alert
          onClose={handleCloseDeleteAlert}
          text={`${attachment.name} (${byteConverter(attachment?.size)})`}
          bodyText={`Etes-vous certains de vouloir supprimer ce fichier ?`}
          deleteAlert={true}
          onSubmit={handleDelete}
        />
      )}
      {showWeightAlert && (
        <Alert
          onClose={handleCloseWeightAlert}
          text={`${attachment.name} (${byteConverter(attachment?.size)})`}
          bodyText={`Reduire le poids du fichier pdf`}
          deleteAlert={true}
          onSubmit={handleWeight}
        />
      )}
      {showCompressedSizeAlert && (
        <Alert
          onClose={handleCloseCompressedSizeAlert}
          text={`${attachment.name} (${byteConverter(attachment?.size)})`}
          bodyText={`A présent ${byteConverter(showCompressedSizeAlert)}`}
          deleteAlert={true}
          noButton
        />
      )}
    </div>
  )
}


export default SingleDocumentAttachments