import { useState, useRef, useContext } from 'react'

import { Plus, InfoCircle, Clipboard, Upload, Close } from '../../assets/icons'
import { Input, CustomTooltip } from '../ui_new'
import { getFileData } from '../../utils'
import { NotificationContext, DocumentsContext, LoaderContext } from '../../context'
import { useAttachmentActions } from '../../hooks'
import { KeyboardDoubleArrowRight } from '@mui/icons-material'

// TODO - save path to db to be able to delete attachment from storage

const SingleDocumentAttachmentsUpload = ({ onSetDocumentAttachments, attachments, docId, onToggle }) => {
  const { copiedAttachments, attachmentsFrom } = useContext(DocumentsContext)
  const { setNotification } = useContext(NotificationContext)
  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { uploadFromUrl, pasteAttachments } = useAttachmentActions(null, null)
  const [url, setUrl] = useState('')
  const dndEl = useRef()
  const fileRef = useRef()

  // On drag over
  const handleDragOver = (e) => {
    e.preventDefault()
    if (dndEl.current) {
      dndEl.current.classList.add('active')
    }
  }

  // On drag leave 
  const handleDragLeave = (e) => {
    e.preventDefault()
    if (dndEl.current) {
      dndEl.current.classList.remove('active')
    }
  }

  // On drop 
  const handleDrop = async (e) => {
    if (dndEl.current) {
      dndEl.current.classList.remove('active')
    }

    const files = e.dataTransfer.files
    e.preventDefault()
    addAttachmentsHelper(files)
  }

  // On file change
  const handleFileChange = async (e) => {
    const files = e.target.files
    addAttachmentsHelper(files)
  }

  const allowedTypes = ['application/pdf']//, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpg', 'image/jpeg', 'image/svg', 'image/svg+xml', 'image/gif']
  // Upload files helper
  const addAttachmentsHelper = async (files) => {
    if (!files) return


    const promises = []
    let errors = []
    const attNames = attachments.map(a => a.name)

    const filesArr = Array.from(files)
    for (let i = 0; i < filesArr.length; i++) {
      const file = filesArr[i]
      let components = file.name.split('.')
      components.splice(components.length - 1, 1)
      const name = components.join('.')

      if (attNames.includes(name)) {
        errors.push(`La pièce jointe avec le nom "${name}" existe déjà`)
        continue
      }

      if (!allowedTypes.includes(file.type)) {
        errors.push(`Type de fichier non valide pour la pièce jointe "${name}"`)
        continue
      }

      if (file.size > 50 * 1024 * 1024) {
        errors.push(`Le poids du fichier pour "${name}" est supérieur au poids autorisé (50MB)`)
        continue
      }

      promises.push(getFileData(file))
    }

    if (errors.length > 0) {
      setNotification({ msg: errors.join('.'), type: 'danger' })
    }

    if (promises.length) {
      const data = await Promise.all(promises)
      onSetDocumentAttachments(prev => [...prev, ...data])
      console.log(data)
    }
  }

  // On open file window 
  const handleOpenFileWindow = () => {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }

  // On url change
  const handleUrlChange = (e) => {
    setUrl(e.target.value)
  }

  // On upload from url
  const handleUploadFromUrl = async (e) => {
    e.preventDefault()
    setShowGlobalResponseLoader(true)
    const data = await uploadFromUrl(url.trim())
    if (data) {
      onSetDocumentAttachments(prev => [...prev, data])
      setUrl('')
    }
    setShowGlobalResponseLoader(false)
  }

  // On paste attachments
  const handlePasteAttachments = async () => {
    setShowGlobalResponseLoader(true)
    await pasteAttachments({ attachmentsNames: attachments.map(a => a.name), setDocumentAttachments: onSetDocumentAttachments })
    setShowGlobalResponseLoader(false)
  }

  return (
    <div className="single-document-preview">
      <div className="single-document-preview__head">
        <h2>
          <span className="text mr-2">ANNEXE</span>
          <CustomTooltip content="Le poids des pièces jointes ne peut excéder 256MB"><span><InfoCircle /></span></CustomTooltip>
          <button className="ml-auto button button--outline-primary-light rounded-sm fold-btn !px-3" onClick={onToggle}>
            Replier
            <KeyboardDoubleArrowRight fontSize={'small'} />
          </button>
          <button className="ml-auto fold-btn-mobile" onClick={onToggle}>
            <Close />
          </button>
        </h2>
      </div>
      <div className="single-document-preview__body">
        <div
          ref={dndEl}
          className="file-upload-v2"
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <div className="file-upload-v2__info">
            <button type="button" className='button' onClick={handleOpenFileWindow}><Upload /> Ajouter une pièce jointe</button>
            <p>ou glisser-déposer une pièce jointe</p>
            <input type="file" ref={fileRef} onChange={handleFileChange} multiple accept={allowedTypes.join(",")} />
          </div>
        </div>
        {copiedAttachments.length > 0 && attachmentsFrom && attachmentsFrom !== docId && (
          <div className="paste-attachments">
            <button className="btn btn--with-icon" onClick={handlePasteAttachments}><Clipboard /> {`Coller les annexes du presse-papier(${copiedAttachments.length})`}</button>
          </div>
        )}
        <div className="upload-from-url">
          <p>Ajout d’une annexe à partir d’une URL</p>
          <form onSubmit={handleUploadFromUrl}>
            <Input value={url} onChange={handleUrlChange} placeholder="https://" />
          </form>
        </div>
      </div>
    </div>
  )
}

export default SingleDocumentAttachmentsUpload