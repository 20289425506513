import { useContext, useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { MainLayout } from '../layouts'
import { FolderOpen } from '../assets/icons'
import { TemplatesContentSidebar, TemplatePreviewModal, TemplatePreviewMobile, AttachmentsModal, StandardTemplatesFilters } from '../components/misc'
import { TemplateFolderBox, Alert } from '../components/ui_new'
import { DocumentsContext, FolderContext, LoaderContext, UserContext } from '../context'
import { sortedArrayFromObject, sorterWithPathAndOrder, sortArrayOfObjects, isFeatureAuthorized } from '../utils'
import { useSingleDocumentActions } from '../hooks'
import EditTemplateModal from '../components/sections/templates/EditTemplateModal'
import { TemplatesTable } from '../components/misc';
import TemplateHistoryModal from '../components/sections/document-detail/TemplateHistoryModal'

const Templates = () => {
  const { createDocumentFromTemplate } = useSingleDocumentActions()
  const { templatesSelectedFilters, setTemplatesSelectedFilters, templates, templatesLoaded, updateTemplate, templatesLoading, deleteTemplate, templatesActiveSort, setTemplatesActiveSort } = useContext(DocumentsContext)
  const { folders, foldersFetched, currentlyActiveFolder, setCurrentlyActiveFolder } = useContext(FolderContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { userClaims } = useContext(UserContext)
  const [rootFolder, setRootFolder] = useState({
    id: 'templates-root-folder',
    name: 'Dossier racine',
    templates: []
  })
  const [rootFolders, setRootFolders] = useState([])
  const [activeTemplates, setActiveTemplates] = useState([])
  const [filteredTemplates, setFilteredTemplates] = useState([])
  const [filteredFolders, setFilteredFolders] = useState([])
  const [ready, setReady] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showManageAttachmentModal, setShowManageAttachmentModal] = useState(false)
  const [currentTemplate, setCurrentTemplate] = useState(null)
  const [showMobilePreview, setShowMobilePreview] = useState(false)
  const [currentMobileTemplate, setCurrentMobileTemplate] = useState(null)
  const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);
  const [showDeleteTemplateAlert, setShowDeleteTemplateAlert] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const history = useHistory()
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  // Set templates
  useEffect(() => {
    const setupTemplates = async () => {
      const copyOfTemplates = { ...templates }
      const filteredTemplates = {}
      let templatesInRootFolder = []
      for (let key in copyOfTemplates) {
        if (!copyOfTemplates[key].deleted) {
          const template = copyOfTemplates[key]
          let folder = ''
          if (template.folderId && Array.isArray(template.folderId) && template.folderId[0]) {
            const folderFound = folders.find(f => f.id === template.folderId[0])
            if (folderFound) {
              folder = folderFound.name
            } else {
              templatesInRootFolder.push(template)
            }
          } else {
            templatesInRootFolder.push(template)
          }
          filteredTemplates[key] = { ...copyOfTemplates[key], folder }
        }
      }
      if (templatesInRootFolder.length > 0) {
        setRootFolder(prev => ({ ...prev, templates: templatesInRootFolder }))
      }
      let sort = 'name'
      let order = 'desc'
      let arr = sortedArrayFromObject(filteredTemplates, sorterWithPathAndOrder(sort, order))
    //   const templates = sortArrayOfObjects(filteredTemplates, sort_by, activeOrder === 'desc' ? 'asc' : 'desc')
    // setActiveSort(sort_by)
    // setActiveOrder(activeOrder === 'desc' ? 'asc' : 'desc')
      setActiveTemplates(arr)
      setFilteredTemplates(arr)
      setReady(true)
    }

    if (templatesLoaded && foldersFetched) {
      setupTemplates()
    }
  }, [templates, templatesLoaded, foldersFetched, folders])

  // Set root folders
  useEffect(() => {
    if (folders && Array.isArray(folders)) {
      setRootFolders(folders.filter(folder => !folder.parentFolder))
    }
  }, [folders])

  // On filter
  const handleFilter = useCallback(() => {
    let filtered = [...activeTemplates]

    let filteredFolds = []
    for (let key in templatesSelectedFilters) {
      if (!templatesSelectedFilters.search) {
        if (key === 'folder' && templatesSelectedFilters[key] !== 'all') {
          filtered = filtered.filter(tmplt => tmplt.folderId && tmplt.folderId.includes(templatesSelectedFilters[key]));
          filteredFolds = folders.filter(f => f.parentFolder === templatesSelectedFilters[key])
        } else if (key === 'folder' && templatesSelectedFilters[key] === 'all') {
          filtered = filtered.filter(tmplt => !tmplt.folderId || tmplt.folderId.length === 0);
          filteredFolds = folders.filter(f => !f.parentFolder)
        }
      }
      if (key === 'search' && templatesSelectedFilters[key] !== '') {
        filtered = filtered.filter(tmplt => tmplt.name.toLowerCase().includes(templatesSelectedFilters[key].trim().toLowerCase()))
      }
      if (key === 'date_after' && templatesSelectedFilters[key] !== '') {
        filtered = filtered.filter(tmplt => tmplt.meta.created > templatesSelectedFilters[key])
      }
      if (key === 'date_before' && templatesSelectedFilters[key] !== '') {
        filtered = filtered.filter(tmplt => tmplt.meta.created < templatesSelectedFilters[key])
      }
    }
    setFilteredFolders(filteredFolds)
    setFilteredTemplates(filtered)
  }, [activeTemplates, templatesSelectedFilters, folders])

  // Watch for filters change
  useEffect(() => {
    handleFilter()
  }, [templatesSelectedFilters, handleFilter])

  // Generate breadcrumb
  useEffect(() => {
    if (currentlyActiveFolder) {
      if (currentlyActiveFolder === 'root') {
        setBreadcrumbs(findItemAndParents(''));
      } else {
        setBreadcrumbs(findItemAndParents(currentlyActiveFolder.id));
      }
    }
  }, [currentlyActiveFolder, folders])

  const findItemAndParents = (itemId) => {
    // Helper function to recursively find parents
    function findParents(parents, currentData, targetId) {
      for (const item of currentData) {
        if (item.id === targetId) {
          // Found the item, add it to the parents array
          parents.push(item);
          if (item.parentFolder) {
            // If it has a parent folder, recursively find its parents
            findParents(parents, folders, item.parentFolder);
          }
          break;
        }
      }
    }

    // Find the item
    const foundItem = folders.find(item => item.id === itemId);
    if (!foundItem) {
      return null; // Item not found
    }
    // Array to store the parents
    const parents = [];
    // Find all parents recursively
    findParents(parents, folders, foundItem.parentFolder);

    // Add the found item to the beginning of the array
    parents.unshift(foundItem);
    return parents.reverse();
  }

  // On preview modal open
  const handleOpenPreviewModal = (template) => {
    setShowPreviewModal(true)
    setCurrentTemplate(template)
  }

  // On preview modal close
  const handleClosePreviewModal = () => {
    setShowPreviewModal(false)
    setCurrentTemplate(null)
  }

  // On manage attachment modal open
  const handleOpenManageAttachmentsModal = (template) => {
    setShowManageAttachmentModal(true)
    setCurrentTemplate(template)
  }

  // On manage attachment modal close
  const handleCloseManageAttachmentsModal = () => {
    setShowManageAttachmentModal(false)
    setCurrentTemplate(null)
  }

  const handleOpenInfoModal = (template) => {
    setCurrentTemplate(template);
    setShowInfoModal(true);
  }

  const handleCloseInfoModal = () => {
    setCurrentTemplate(null);
    setShowInfoModal(false);
  }

  const handleOpenHistoryModal = (template) => {
    setCurrentTemplate(template);
    setShowHistoryModal(true);
  }

  const handleCloseHistoryModal = () => {
    setCurrentTemplate(null);
    setShowHistoryModal(false);
  }

  // On mobile preview open
  const handleOpenMobilePreview = (template) => {
    setShowMobilePreview(true)
    setCurrentMobileTemplate(template)
  }

  // On mobile preview close
  const handleCloseMobilePreview = () => {
    setShowMobilePreview(false)
    setCurrentMobileTemplate(null)
  }

  // On create document
  const handleCreateDocument = async (template) => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Création du document')
    try {
      const id = await createDocumentFromTemplate(template)
      history.push(`/documents/${id}`)
    } catch (err) {

    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
    }
  }


  const editTemplateClickHandler = (template) => {
    setCurrentTemplate(template);
    setShowEditTemplateModal(true);
  }

  const updateTemplateHandler = async (data, id) => {
    await updateTemplate(data, id);
    setShowEditTemplateModal(false);
    setCurrentTemplate(null);
  }

  const deleteTemplateClickHandler = (template) => {
    setCurrentTemplate(template);
    setShowDeleteTemplateAlert(true);
  }

  const deleteTemplateHandler = async () => {
    await deleteTemplate(currentTemplate.id);
    setShowDeleteTemplateAlert(false);
    setCurrentTemplate(null);
  }

  const toggleTemplateHiddenClickHandler = async (template) => {
    await updateTemplate({ hidden: !template.hidden }, template.id);
  }

  // Handle sort
  const handleSort = (sort_by, order) => {
    setTemplatesActiveSort((prev) => ({ ...prev, [sort_by]: order, activeItem: sort_by }))
  }

  useEffect(() => {
    const currentOrder = filteredTemplates.map((template) => template.id).join('')
    const templates = sortArrayOfObjects(filteredTemplates, templatesActiveSort.activeItem, templatesActiveSort[templatesActiveSort.activeItem])
    const newOrder = templates.map((template) => template.id).join('')
    if (currentOrder !== newOrder) {
      setFilteredTemplates([...templates]) 
    }
  }, [templatesActiveSort, filteredTemplates])

  const handleSelectFolderInTable = (folder) => {
    setTemplatesSelectedFilters(prev => ({ ...prev, folder: folder.id }))
    setCurrentlyActiveFolder(folder)
  }

  return (
    <MainLayout className="page-templates" pageTitle="Modèles" hideTitleOnDesktop={true} titleIcon={<FolderOpen />} showSearch={true} searchIn="templates">
      <div className="templates-content">
        <TemplatesContentSidebar
          selectedFilters={templatesSelectedFilters}
          onSetSelectedFilters={setTemplatesSelectedFilters}
          rootFolders={rootFolders}
        />

        <div className="templates-content__main">
          {!templatesSelectedFilters.search &&
            <ul className='flex mb-5 breadcrumb'>
              <li>Dossier racine</li>{breadcrumbs?.map((item, index) => (
                <li key={item.id}>
                  &nbsp;/&nbsp;{item.name}
                </li>
            ))}
            </ul>
          }
          {!ready && (
            <div className="loader-wrapper">
              <div className="loader-v2"></div>
            </div>
          )}
          {ready && <>
            <StandardTemplatesFilters selectedFilters={templatesSelectedFilters}
              onSetSelectedFilters={setTemplatesSelectedFilters}
              onSort={handleSort}
              activeSort={templatesActiveSort}
              view="templates"/>
            <TemplatesTable
              filteredTemplates={filteredTemplates.filter((template) => (isFeatureAuthorized({ userClaims, rule: 'any_admin' }) || !template.hidden))}
              filteredFolders={filteredFolders}
              onSort={handleSort}
              onPreview={handleOpenPreviewModal}
              onInfo={handleOpenInfoModal}
              onHistory={handleOpenHistoryModal}
              onCreateDocument={handleCreateDocument}
              onTemplateDelete={deleteTemplateClickHandler}
              onTemplateEdit={editTemplateClickHandler}
              onTemplateToggleHidden={toggleTemplateHiddenClickHandler}
              currentlyActiveFolder={currentlyActiveFolder}
              rootFolders={rootFolders}
              onSelectFolder={handleSelectFolderInTable}
              onManageAttachments={handleOpenManageAttachmentsModal}
              view="templates" />

            {/* <ul className="templates-list">
              {filteredTemplates.filter((template) => (isFeatureAuthorized({ userClaims, rule: 'any_admin' }) || !template.hidden)).map((template) => {
                return (
                  <TemplateBox
                    key={template.id}
                    template={template}
                    onPreview={handleOpenPreviewModal}
                    onCreateDocument={handleCreateDocument}
                    onTemplateDelete={deleteTemplateClickHandler}
                    onTemplateEdit={editTemplateClickHandler}
                    onTemplateToggleHidden={toggleTemplateHiddenClickHandler} />
                )
              })}
            </ul> */}
          </>}
          {/* {ready && (
            <div className="templates-content__main_mobile">
              <TemplateFolderBox folder={rootFolder} folderTemplates={rootFolder.templates} onPreview={handleOpenMobilePreview} />
              {rootFolders.map((folder) => {
                  const folderTemplates = activeTemplates.filter(tmplt => tmplt.folderId && Array.isArray(tmplt.folderId) && tmplt.folderId.includes(folder.id))
                  return (
                    <TemplateFolderBox key={folder.id} folder={folder} folderTemplates={folderTemplates} onPreview={handleOpenMobilePreview} />
                  )
                })
              }
            </div>
          )} */}
        </div>
      </div>

      {showManageAttachmentModal && (
        <AttachmentsModal
          onClose={handleCloseManageAttachmentsModal}
          template={currentTemplate}
          attachment={null}
          docId={null}
        />
      )}

      {showPreviewModal && (
        <TemplatePreviewModal
          onClose={handleClosePreviewModal}
          template={currentTemplate}
        />
      )}

      {showEditTemplateModal && <EditTemplateModal
        onClose={() => setShowEditTemplateModal(false)}
        onEdit={updateTemplateHandler}
        templatesLoading={templatesLoading}
        currentTemplate={currentTemplate}
      />}

      {showDeleteTemplateAlert && <Alert
        onClose={() => setShowDeleteTemplateAlert(false)}
        text="Etes-vous certain de vouloir supprimer ce modèle? Les documents dépendants de ce modèle ne pourront plus être générés."
        onSubmit={deleteTemplateHandler}
        loading={templatesLoading}
        deleteAlert
      />}

      {(showInfoModal || showHistoryModal) && <TemplateHistoryModal mode={showHistoryModal ? 'history' : 'info'} onClose={() => {
        handleCloseInfoModal()
        handleCloseHistoryModal()
      }} template={currentTemplate} />}

      <TemplatePreviewMobile opened={showMobilePreview} template={currentMobileTemplate} onClose={handleCloseMobilePreview} />
    </MainLayout>
  )
}

export default Templates