import { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { ClickAwayListener } from '@material-ui/core'
import { DocumentsContext, FolderContext, LoaderContext, NotificationContext, UserContext } from '../../context'
import { useSingleDocumentActions } from '../../hooks'
import { ArrowBack, ChevronRight, Favorite, FavoriteBorder, FolderOutlined, Add, InsertDriveFileOutlined, Source, Folder } from '@mui/icons-material';
import { isFeatureAuthorized } from '../../utils'

const AddNewDocument = ({ showDropdown, setShowDropdown, handleToggleDropdown, handleToggleMobileMenu }) => {
  const { createDocumentFromTemplate } = useSingleDocumentActions()
  const { folders, foldersFetched, standardTemplatesFolders, standardTemplatesFoldersFetched } = useContext(FolderContext)
  const { templates, templatesLoaded, standardTemplates, standardTemplatesLoaded } = useContext(DocumentsContext)
  const { setShowGlobalResponseLoader, setGlobalResponseLoaderText } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { userClaims } = useContext(UserContext)
  const [isActive, setIsActive] = useState(false);

  const defaultDropdownOptions = [
    {
      title: null,
      items: [{
        folder: null,
        type: 'standard-templates',
        content: (
          <>
            <span className="icon"><FavoriteBorder /></span>
            <span className='text'>Mes modèles types</span>
          </>
        )
      }, {
        folder: null,
        type: 'templates',
        content: (
          <>
            <span className="icon"><FolderOutlined /></span>
            <span className='text'>Modèles</span>
          </>
        )
      }]
    }
  ]
  const [dropdowns, setDropdowns] = useState(defaultDropdownOptions)

  const [rootFolderTemplates, setRootFolderTemplates] = useState({
    "templates": [],
    "standard-templates": []
  })
  const [formattedFolders, setFormattedFolders] = useState({
    "templates": [],
    "standard-templates": []
  })
  const history = useHistory()

  // Set root folder templates
  useEffect(() => {
    if (!(templatesLoaded && foldersFetched && standardTemplatesLoaded && standardTemplatesFoldersFetched)) {
      return
    }
    const rootTemplates = {
      "templates": [],
      "standard-templates": []
    }
    for (let key in templates) {
      if(!isFeatureAuthorized({ userClaims, rule: 'any_admin' }) && templates[key].hidden) {
        continue
      }
      if (templates[key].deleted) {
        continue
      }
      const template = { ...templates[key] }
      if (!template.folderId || !Array.isArray(template.folderId) || template.folderId.length === 0) {
        rootTemplates["templates"].push({ ...template, id: key })
      }
    }
    for (let key in standardTemplates) {
      if (!standardTemplates[key].deleted) {
        const template = { ...standardTemplates[key] }
        if (!template.folderId || !Array.isArray(template.folderId) || template.folderId.length === 0) {
          rootTemplates["standard-templates"].push({ ...template, id: key })
        }
      }
    }
    setRootFolderTemplates(rootTemplates)
  }, [templates, templatesLoaded, foldersFetched, standardTemplates, standardTemplatesLoaded, standardTemplatesFoldersFetched, userClaims])

  useEffect(() => {
    let procFolders = {
      "templates": {},
      "standard-templates": {}
    }
    for (let i in folders) {
      let folder = { ...folders[i] }
      while (folder.parentFolder) {
        const currentFolderParentId = folder.parentFolder
        let parent = folders.find(f => f.id === currentFolderParentId)
        if (!parent) break
        parent = { ...parent }
        if (!parent.subfolders) {
          parent.subfolders = []
        }
        if (!parent.subfolders.find(f => f.id === currentFolderParentId)) {
          parent.subfolders.push(folder)
        }
        folder = parent
      }
      if (!folder.parentFolder) {
        procFolders["templates"][folder.id] = folder
      }
    }

    let procTemplateFoldersArray = Object.values(procFolders["templates"])
    for (let i in standardTemplatesFolders) {
      let folder = { ...standardTemplatesFolders[i] }
      while (folder.parentFolder) {
        const currentFolderParentId = folder.parentFolder
        let parent = standardTemplatesFolders.find(f => f.id === currentFolderParentId)
        if (!parent) break
        parent = { ...parent }
        if (!parent.subfolders) {
          parent.subfolders = []
        }
        if (!parent.subfolders.find(f => f.id === currentFolderParentId)) {
          parent.subfolders.push(folder)
        }
        folder = parent
      }
      if (!folder.parentFolder) {
        procFolders["standard-templates"][folder.id] = folder
      }
    }
    let procStandardTemplateFoldersArray = Object.values(procFolders["standard-templates"])
    setFormattedFolders({
      "templates": procTemplateFoldersArray,
      "standard-templates": procStandardTemplateFoldersArray
    })

  }, [folders, standardTemplatesFolders])

  // On mouse over
  const handleMouseOver = () => {
    if (!isActive) setIsActive(true)
  }

  // On click away 
  const handleClickAway = () => {
    if (isActive) setIsActive(false)
    if (showDropdown) setShowDropdown(false)
  }

  // On create document
  const handleCreateDocument = async (tmplt, type = 'templates') => {
    setShowGlobalResponseLoader(true)
    setGlobalResponseLoaderText('Création du document')
    try {
      let id
      if (type === 'templates') {
        id = await createDocumentFromTemplate(tmplt)
      } else if (type === 'standard-templates') {
        id = await createDocumentFromTemplate(tmplt, tmplt.values, tmplt.template)
      }
      history.push(`/documents/${id}`)
    } catch (err) {
      setNotification({ msg: err.message, type: 'danger' })
    } finally {
      setShowGlobalResponseLoader(false)
      setGlobalResponseLoaderText('')
      handleToggleMobileMenu && handleToggleMobileMenu()
    }
  }

  // TODO test on mobile and fix opening doc when opening folder

  const handleActivateDropdownItem = (folder, type, template, eventType) => {
    const newDropdowns = [...dropdowns]
    if (!folder && !type && !template) return

    if (template && eventType === "click") {
      handleCreateDocument(template, type)
      handleClickAway()
      return
    }

    // build folders column
    const foldersItems = []
    const allFolders = type === 'templates' ? folders : type === 'standard-templates' ? standardTemplatesFolders : [];

    for (let i = 0; i < allFolders.length; i++) {
      const folderItem = allFolders[i]
      if (!folder || folderItem.parentFolder === folder.id) {
        foldersItems.push({
          folder: folderItem,
          type: type,
          template: null,
          level: 0,
          content: (
            <>
              <span className="icon">{folderItem.parentFolder ? <Source /> : <Folder />}</span>
              <span className='text'>{folderItem.name}</span>
            </>
          )
        })
      }
    }

    if (!folder) {
      folder = { id: "root" }
    }
    const source = type === 'templates' ? Object.keys(templates).map(id => ({ ...templates[id], id })) : type === 'standard-templates' ? Object.keys(standardTemplates).map(id => ({ ...standardTemplates[id], id })) : []
    let filteredTemplates = []
    if (folder.id === "root") {
      filteredTemplates = rootFolderTemplates[type]
    } else {
      filteredTemplates = source
      .filter(template => template.folderId && template.folderId.includes(folder.id))
      .filter(template => !template.deleted)
      .filter(template => !template.hidden || isFeatureAuthorized({ userClaims, rule: 'any_admin' }))
    }
    newDropdowns.push({
      title: folder.id === "root" ? "Dossier racine" : folder.name,
      folderId: folder.id,
      items: [
        ...foldersItems,
        ...filteredTemplates.map((t) => {
          return {
            template: t,
            folder: folder,
            type: type,
            content: (
              <>
                <span className="icon"><InsertDriveFileOutlined /></span>
                <span className='text doc-name'>{t.name}</span>
              </>
            )
          }
        })
      ]
    })
    setDropdowns(newDropdowns)
  }

  const rowNestLevelOffset = 10
  const goBack = () => {
    //remove last dropdown item
    setDropdowns(dropdowns.slice(0, -1))
  }
  const renderDropdownMenu = (title, items, folderId) => {
    return (
      <div className={`add-new-document__dropdown`}>
        {title && <div className="add-new-document__dropdown-header">
          <button className='button button--transparent return' onClick={goBack}><ArrowBack fontSize={'small'} />Retour</button>
        </div>}
        <ul>
          {items.map((item, idx) => {
            // I added this condition to fix: Subfolders should not appear at the same time as the main folders.
            if (item.template || ((folderId === 'root' && !item.folder?.parentFolder) || (folderId === item.folder?.parentFolder))) {
              return <li key={idx} onClick={() => handleActivateDropdownItem(item.folder, item.type, item.template, "click")}>{
                <>
                  <a>
                    {item.level > 0 && <span style={{ marginLeft: item.level * rowNestLevelOffset }}></span>}
                    {item.content}
                  </a>
                  {!item.template && <ChevronRight className="cursor-pointer" />}
                </>
              }</li>
            }
          }
          )}
          {items.length === 0 && <span className="empty-folder">Dossier vide</span>}
        </ul>
      </div>
    )
  }

  const renderDropdown = () => {
    if (!showDropdown) return null

    return (
      <div className='add-new-document__dropdown-menu-wrapper'>
        {renderDropdownMenu(dropdowns[dropdowns.length - 1].title, dropdowns[dropdowns.length - 1].items, dropdowns[dropdowns.length - 1].folderId)}
      </div>
    )
  }
  const toggle = () => {
    handleToggleDropdown();
    setDropdowns(defaultDropdownOptions);
  }

  return (
    <div className="add-new-document">
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="add-new-document__body-wrapper">
          <div className={`add-new-document__body ${showDropdown ? 'active ' : ''}`}
            onClick={toggle}>
            <Add />
            <div className="add-new-document__body_main">
              <p>Nouveau document</p>
            </div>
          </div>
          {renderDropdown()}
        </div>
      </ClickAwayListener>
    </div>
  )
}

export default AddNewDocument